<template>
  <div class="course-container" style="min-height: 670px;">
    <div class="course-banner"></div>
    <div class="course-info container mx-auto px-3">
        <el-image style="width: 400px;height: 200px" :src="course.cover"></el-image>
        <h1 class="ml-3 text-white" style="font-size: 24px">{{ course.name }}</h1>
      </div>
    <div class="course-content container mx-auto my-3 px-3">
      <el-tabs v-model="activeName" class="rounded-sm">
        <el-tab-pane label="介绍" name="brief">{{ course.brief }}</el-tab-pane>
        <el-tab-pane label="目录" name="contents">
          <el-tree v-if="treeList.length > 0" class="chapter py-3 px-2 border border-gray-200" :data="treeList">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span @click="goChapter(data)">
                <span v-if="data.type === 7">【PPT课程】</span>
                <span v-else-if="data.type === 8">【伴随学习】</span>
                <span v-else-if="data.type === 9">【单元测试】</span>
                <span v-else-if="data.type === 10">【程序设计】</span>
                {{ node.label }}
              </span>
            </span>
          </el-tree>
        </el-tab-pane>
        <!-- <el-tab-pane label="作业" name="homework">作业</el-tab-pane>
        <el-tab-pane label="考试" name="exam">考试</el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>

import { detail } from '@/api/course' 

export default {
  data() {
    return {
      course: {
        id: 0,
        name: '',
        cover: ''
      },
      activeName: 'contents',
      treeList: []
    }
  },
  mounted() {
    this.course.id = this.$route.query.id
    detail({
      id: this.course.id
    }).then(res => {
      this.course = res.data.course
      this.treeList = res.data.chapters.map(item => {
        let tempItem = {}
        tempItem.id = item.id
        tempItem.label = item.name
        tempItem.children = item.sections?.map(subItem => {
          let tempSubItem = {}
          tempSubItem.id = subItem.id
          tempSubItem.cid = item.id
          tempSubItem.label = subItem.name
          tempSubItem.type = subItem.type
          return tempSubItem
        })
        return tempItem
      })
    })
  },
  methods: {
    goChapter(item) {
      if (item?.cid) {
        this.$router.push({ name: 'ChapterIndex', params: { id: item.cid, sid: item.id }})
      }
    }
  }
}
</script>
<style>
  .chapter .el-tree-node__content {
    height: 40px;
  }
</style>
<style lang="scss" scoped>
  .course-banner {
    position: absolute;
    width: 100%;
    height: 300px;
    background: url('https://haimakid-cdn.haimakid.net/xinao/banner-1.jpg') 50%/cover;
    z-index: -1;
  }
  .course-info {
    display: flex;
    align-items: center;
    height: 300px;
  }
  .custom-tree-node {
    width: 100%;
    text-align: left;
  }
  .custom-tree-node>span {
    display: block;
    width: 100%;
  }
  
</style>